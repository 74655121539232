<template>
  <div class="w1092">
      <p class="myfix"><span class="">{{userData.memNick}}</span>{{$t('front.mypage.infoEdit')}}<br>{{$t('front.mypage.security')}}</p>
      <div class="myfixwrap">
        <ul class="joinu">
          <li>{{$t('front.common.memId')}}</li>
          <li>{{userData.memId}}</li>
        </ul>
        <ul class="joinu">
          <li>{{$t('front.common.password')}}</li>
          <li><input type="password" placeholder="" v-model="model.memPass"></li>
        </ul>
        <span class="joinutext">{{$t('front.signup.passwordPlaceholder')}}</span>
        <ul class="joinu">
          <li>{{$t('front.mypage.passwordOk')}}</li>
          <li><input type="password" placeholder="" v-model="model.memPassCheck"></li>
        </ul>
        <ul class="joinu">
          <li>{{$t('front.common.cashOutPass')}}</li>
          <li><input type="text" id="cashOutPass"  maxlength="4"  pattern="[0-9]*" v-model="model.cashOutPass" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"  inputmode="numeric"  placeholder="" /></li>
        </ul>
        <span class="joinutext">{{$t('front.signup.cashOutPassPlaceholder')}}</span>
        <ul class="joinu">
          <li>{{$t('front.mypage.ExchangepasswordOk')}}</li>
          <li><input  type="text" id="cashOutPassCheck" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"  maxlength="4"  pattern="[0-9]*" inputmode="numeric"  placeholder="" v-model="model.cashOutPassCheck"></li>
        </ul>
      </div>
      <div class="boardbtn"><a class="" @click="onUpdate">{{$t('front.board.edit')}}</a></div>
      <div class="joinubottom">
        <ul>
          <li>{{$t('front.common.level')}}</li>
          <li>[LV. {{userData.memLevel}}]</li>
        </ul>
        <!--ul class="joinu">
          <li>스포츠</li>
          <li>레벨 LV. {{userData.sportsLevel}}</li>
        </ul-->
        <ul>
          <li>{{$t('front.gnb.casino')}}</li>
          <li>[LV. {{userData.casinoLevel}}]</li>
        </ul>
        <ul>
          <li>{{$t('front.gnb.slot')}}</li>
          <li>[LV. {{userData.slotLevel}}]</li>
        </ul>
        <!--ul class="joinu">
          <li>미니게임</li>
          <li>레벨 LV. {{userData.mgLevel}}</li>
        </ul-->
      </div>
    </div>
</template>

<script>
import { isValidOnlyNumber, isValidPassword } from '@/libs/utils'
import { memberUpdate } from '@/api/member'

export default {
  name: 'MyPageInfo',
  data () {
    return {
      model: {
        memPass: '',
        cashOutPass: '',
        cashOutPassCheck: '',
        memPassCheck: ''
      }
    }
  },
  methods: {
    isValidate () {
      const data = this.model
      for (const key of Object.keys(data)) {
        const value = data[key]

        if (key === 'memPassCheck') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.compareMemPass')
            return false
          }
        }

        if (key === 'memPass') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyMemPass')
            return false
          }

          if (!isValidPassword(value) || value.length > 20) {
            this.onAlert('warningart', 'api.U102')
            return false
          }
        }

        if (key === 'cashOutPassCheck') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyCashOutPass')
            return false
          }
        }

        if (key === 'cashOutPass') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyCashOutPass')
            return false
          }

          if (!isValidOnlyNumber(value) || value.toString().length !== 4) {
            this.onAlert('warningart', 'api.U103')
            return false
          }
        }

        if (data.memPass !== data.memPassCheck) {
          this.onAlert('warningart', 'front.member.compareMemPass')
          return false
        }

        if (data.cashOutPass !== data.cashOutPassCheck) {
          this.onAlert('warningart', 'front.member.compareCashPassCheck')
          return false
        }
      }

      return true
    },
    onUpdate () {
      if (this.isValidate()) {
        const param = {
          memId: this.userData.memId,
          memPass: this.model.memPass,
          cashOutPass: this.model.cashOutPass
        }

        memberUpdate(param).then(response => {
          const result = response.data
          if (result.resultCode === '0') {
            this.onCheck('front.member.completeUpdate')
          } else {
            this.onCheck('api.' + result.resultCode)
          }

          this.model = {
            memPass: '',
            cashOutPass: '',
            cashOutPassCheck: ''
          }
        })
      }
    }
  }
}
</script>
<style scoped>
#cashOutPass, #cashOutPassCheck {
  -webkit-text-security: disc;
}

.myfix {font-size: 14px;line-height: 1.5em;color: #bfbfbf;}
.myfix span {color: #f89e3f;}
.myfixwrap {margin-top: 12px;padding: 25px 20px;border-radius: 3px;border: solid 1px #ef6621;background-color: #1b1a20;display: flex;flex-direction: column;gap: 16px;}
.joinu {display: flex;gap: 22px;flex-wrap: wrap;}
.joinu li:first-child {width: 150px;box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.86);border: solid 1px #4a4a56;background-color: #2a2830;justify-content: center;}
.joinu li {width: calc(100% - 174px);position: relative;display: flex;align-items: center;font-size: 14px;color: #fff;height: 40px;}
.joinutext {font-size: 14px;color: #bfbfbf;padding: 0 0 0 180px;text-align: left;display: block;}
.joinu input {padding: 0 0 0 10px;height: 40px;width: calc(100% - 10px);font-size: 14px;color: #fff;border: solid 1px #27272f;background-color: #000;}
.joinubottom {border: solid 1px #ef6621;background-color: #1b1a20;border-radius: 3px;padding: 18px 0;display: flex;align-items: center;justify-content: center;gap: 110px;color: #fff;font-size: 14px;color :#ef6621;}
.joinubottom > ul {display: flex;gap: 10px;}
.joinubottom > ul li:first-child {color: #bfbfbf;}

.boardbtn {display: flex;justify-content: center;align-items: center;}
.boardbtn a {color: #fff;font-size: 14px;text-shadow: 0 1px 3px rgba(0, 0, 0, 0.47);display: flex;align-items: center;justify-content: center;width: 126px;height: 38px;border-radius: 3px;box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.16);background-image: linear-gradient(358deg, #ef6621 98%, #f89e3f 6%);}
</style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
